<template>
  <div class="light-swiper-content">
    <template v-if="pictureList.length > 1">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in pictureList" :key="index">
            <div class="swiper-box">
              <div class="swiper-img">
                <el-image :src="item.attachment.pub_url" alt="" class="picture"
                  :preview-src-list="[item.attachment.pub_url]" :fit=" 'cover' ">
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination">

        </div>
      </div>
      <div class="works-next">

      </div>
      <div class="works-prev">

      </div>
    </template>
    <template v-else>
      <div class="single-img-wraper flex-center">
        <div class="single-img-box" v-for="(item, index) in pictureList" :key="index">
          <div class="single-box">
            <el-image :src="item.attachment.pub_url" alt="" class="picture"
              :preview-src-list="[item.attachment.pub_url]" :fit=" 'cover' ">
            </el-image>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Swiper from 'swiper';
  import 'swiper/css/swiper.css';

  export default {
    data() {
      return {}
    },
    props: {
      pictureList: {
        type:Array,
        default: []
      }
    },
    watch: {
      pictureList() {
        this.initSwiper()
      }
    },
    created() {

    },
    mounted() {
      this.initSwiper()
    },
    methods: {
      initSwiper() {
        this.$nextTick(() => {
          var commentswiper = new Swiper('.swiper-container', {
            slidesPerView: 1,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.works-next',
              prevEl: '.works-prev',
            },
            observer: true,
          });
        })
      }
    }
  }
</script>
<style>
  .works-prev.swiper-button-disabled {
    display: none;
  }

  .works-next.swiper-button-disabled {
    display: none;
  }

  .light-swiper-content .swiper-pagination .swiper-pagination-bullet {
    width: 40px;
    height: 4px;
    background: #DDE5EF;
    border-radius: 6px;
  }

  .light-swiper-content .swiper-container .swiper-pagination-bullet-active {
    width: 40px;
    height: 4px;
    background: #99A5B4;
    border-radius: 6px;
  }
</style>
<style lang="sass" scoped>
  @import '@/common/style/lightSwiper.scss';
</style>