<template>
	<div class="materials-content">
		<div class="swiper-content" v-if="picList.length > 0">
		    <div class="pic-next">
		
		    </div>
		    <div class="pic-prev">
		
		    </div>
		    <div class="pic-container">
		        <div class="swiper-wrapper">
		            <div class="swiper-slide" v-for="(item, index) in picList" :key="index">
		                <div class="swiper-box" @click="viewPicture(item,index)">
		                    <el-image class="img" :src="item.attachment.pub_url" :fit="'cover'" :preview-src-list="[item.attachment.pub_url]"></el-image>
		                </div>
		            </div>
		        </div>
		    </div>
		    <div class="show-comment">
		        <div v-for="(item, index) in picList" :key="index">
		            <template v-if="index == picIndex">
		                    <template v-if="item.comment && item.comment.content">
		                                {{item.comment.content}}
		                    </template>
		                    <template v-else>
		                                该图片暂无说明
		                    </template>
		            </template>
		        </div>
		    </div>
		</div>
		<div class="material-list" v-if="fileList.length > 0"  :style="isMobile == true ? 'width:315px' : ''">
		<div class="title">
			其他素材
		</div>
		<div class="material-contain">
		    <template v-for="(item, index) in fileList">
		        <div class="material-box">
		            <div class="file-content">
		                <div class="file-img"><img src="@/pages/index/imgs/file_icon.svg" alt=""></div>
		                    <div class="file-name"><a class="file-text" target="_blank"
		                                        :href="item.attachment.pub_url" download>
		                        <div class="name-word" :title="item.attachment.file_name">
		                                <span>{{item.attachment.file_name | toFilename}}</span>
		                                <span>{{item.attachment.file_name | toSuffix}}</span>
		                        </div>
		                        </a>
		                        <div class="file-size">({{item.attachment.size / 1024 / 1024 | toFixedFn}}MB)</div>
		                        </div>
		                    </div>
		            </div>
		    </template>
		</div>
		</div>
    <div class="text-wraper" v-if="text_base_text_raw">
      <div class="title">
        已有文案
      </div>
      <div class="text-content" v-html="text_base_text_raw">

      </div>
    </div>
	</div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
	data() {
		return {
			picIndex: 0,
      isMobile: (/Android|webOS|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent)),
		}
	},
	props:{
		picList: {
		  type: Array,
		  default:[]
		},
		fileList: {
		  type: Array,
		  default:[]
		},
    text_base_text_raw: {
      type: String,
      default: ""
    }
	},
	filters: {
	    toFixedFn(data) {
	        let num = new Number(data)
	        return num.toFixed(2)
	    },
	    toSuffix(data) {
	        let file_suffix = data.substring(data.lastIndexOf('.'))
	        return file_suffix
	    },
	    toFilename(data) {
	        let file_word = data.substring(0, data.indexOf("."))
	        return file_word
	    }
	},
	created() {
		if(this.picList.length > 0) {
			this.initPicSwiper()
		}
	},
	mounted() {
		
	},
	methods:{
		initPicSwiper() {
                const self = this
                this.$nextTick(() => {
                    var myPicSwiper = new Swiper('.pic-container', {
                        slidesPerView: "auto",
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: '.pic-next',
                            prevEl: '.pic-prev',
                        },
                        observer: true,
                        observeParents: true,
                });
            })
        },
		viewPicture(item,index) {
			this.picIndex = index
		},
	}
}
</script>
<style>
.pic-container::-webkit-scrollbar{
	display: none
}	
</style>
<style lang="sass" scoped>
@import '@/common/style/materials.scss';
</style>
