<template>
    <div id="app">
       
        <router-view/>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            isMobile: (/Android|webOS|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent)),
        };
    },
    components:{
      
    },
    mounted(){

    }
};
</script>

<style lang="sass">
    @import "./common/style/global.scss"
</style>