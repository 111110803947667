<template>
	<div class="task-detail-main">
		<template v-if="isMobile">
		  <detailMobile></detailMobile>
		</template>
		<template v-else>
			<detailPc></detailPc>
		</template>
	</div>
</template>
<script>
import detailPc from './detailPc.vue'
import detailMobile from './detailMobile.vue'
export default {
	data() {
		return{
			isMobile: (/Android|webOS|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent)),
		}
	},
	components:{
		detailPc,
		detailMobile
	},
	created() {
		
	},
	methods:{

	}
}	
</script>
<style>
	.task-detail-main{
		width: auto;
		height: 100%;
		margin-top: 100px;
		background: #fff;
	}
</style>