import axios from 'axios';
import { Message } from 'element-ui';

const $http = axios.create({
    baseURL: 'https://edit.renwudingdong.com',
});

export const get = (url,params)=>{
    params = params || {};
    return new Promise((resolve,reject)=>{
        $http.get(url,{
            params,
        }).then(res=>{
            if(res.data.errCode === 0){
                resolve(res.data.data);
            }else{
                Message({
                    message: res.data.errMsg,
                    type: 'error'
                });
            }
        }).catch((error)=>{
            Message({
                message: error + ' 网络异常',
                type: 'error'
            });
            reject(error);
        });
    });
};

export const post = (url,params)=>{
    params = params || {};
    return new Promise((resolve,reject)=>{
        $http.post(url,params).then(res=>{
            if(res.data.errCode === 0){
                resolve(res.data.data);
            }else{
                Message({
                    message: res.data.errMsg,
                    type: 'error'
                });
            }
        }).catch(error => {
            Message({
                message: error + ' 网络异常',
                type: 'error'
            });
            reject(error);
        });
    });
};


export const put = (url,params)=>{
    params = params || {};
    return new Promise((resolve,reject)=>{
        $http.put(url,params).then(res=>{
            if(res.data.errCode === 0){
                resolve(res.data.data);
            }else{
                Message({
                    message: res.data.errMsg,
                    type: 'error'
                });
            }
        }).catch((error) => {
            Message({
                message: error + ' 网络异常',
                type: 'error'
            });
            reject(error);
        });
    });
};