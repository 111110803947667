<template>
    <div class="tasks-details-page">
        <headSale v-if="p == 0"></headSale>
        <div slot="title" class="main-title">客户选择的偏好样例</div>
        <div class="container" v-loading="loading">
            <div class="left">
                <div class="sub-title">
                    <span class="name">{{detailInfo.category.name}}</span>
                </div>
                <div class="task-pic-content" v-if="pictureList.length > 0">
                    <swiperView :pictureList="pictureList"></swiperView>
                </div>
                <div class="customer-demand-description" v-if="detailInfo.short_description">
                    <div class="main-title">客户需求描述</div>
                    <div class="content" v-html="detailInfo.short_description">

                    </div>
                </div>
                <div class="delivery-conditions">
                    <div class="main-title">交付细则</div>
                    <div class="conditions">
                        <div class="content-left">
                            <div class="item" v-if="detailInfo.requirements && detailInfo.requirements[0]">{{detailInfo.requirements[0].type.name}}：
                                <div class="item-name">
                                    {{detailInfo.requirements[0].name}}
                                </div>
                            </div>
                            <div class="item" v-if="detailInfo.requirements && detailInfo.requirements[1]">{{detailInfo.requirements[1].type.name}}：
                                <div class="item-name">
                                    {{detailInfo.requirements[1].name}}
                                </div>
                            </div>
                            <div class="item" v-if="detailInfo.requirements && detailInfo.requirements[2]">{{detailInfo.requirements[2].type.name}}：
                                <div class="item-name">
                                    {{detailInfo.requirements[2].name}}
                                </div>
                            </div>
                            <div class="item">{{ businessType == 1 ? '图片' : '文案'}}交付数量：
                                <div class="item-name">
                                    {{detailInfo.picture_num}}{{detailInfo.category.unit}}
                                </div>
                            </div>
                    </div>
                    </div>
                </div>
                <div class="material-wraper" v-if="picList.length > 0 || fileList.length > 0 || detailInfo.ticket.text_base_text_raw">
                    <div class="main-title">已有素材</div>
                    <materialView :picList="picList" :fileList="fileList" :text_base_text_raw="detailInfo.ticket.text_base_text_raw"></materialView>
                </div>
            </div>

            <div class="right">
                <template v-if="p == 0">
                    <div class="sign-up-wrapper">
                        <div class="title">￥{{detailInfo.sign_up_price}}.00</div>
                        <div class="pay-info">
                            <div>支付状态：已支付</div>
                            <div>交付日期：{{detailInfo.deadline}}</div>
                        </div>
                    <div class="service" v-if="skillList.length > 0">
                        <div>进阶服务：</div>
                        <div class="item" v-for="(item,index) in skillList" :key="index">
                            <img src="@/pages/index/imgs/skill_icon.svg">
                            <div class="text">{{item}}</div>
                        </div>
                    </div>
                    <el-button class="sign-up-btn" type="primary" size="medium" round @click="signUp" v-if="sign == 0">立即报名</el-button>
                </div>
                </template>
                <template v-else>
                    <div class="sign-up-wrapper" v-if="skillList.length > 0">
                            <div class="service"  >
                                <div>进阶服务：</div>
                                <div class="item" v-for="(item,index) in skillList" :key="index">
                                    <img src="@/pages/index/imgs/skill_icon.svg">
                                    <div class="text">{{item}}</div>
                                </div>
                            </div>
                    </div> 
                    <el-button class="sign-up-btn-other" type="primary" size="medium" round @click="signUp" v-if="sign == 0">立即报名</el-button>        
                </template>    
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../common/api';
import swiperView from '@/pages/index/components/lightSwiper.vue';
import materialView from '@/pages/index/components/materials.vue';
import headSale from '@/pages/index/components/headsale.vue';
import designerReview from '@/pages/index/components/designerReview.vue';
import {
    pushObjValue
} from '../../common/util/common.js';
export default {
    name: 'detailPc',
    components: {
        swiperView,
        materialView,
        designerReview,
        headSale, 
    },
    data() {
        return {
            detailInfo: {
                picture: [],
                category: {},
                ticket:{},
                like_pictures_score_v2: {}
            },
            idReview: false,
            skillList: [],
            typeParams: {
                business_type: 1
            },
            xData: [],
            loading: false,
            sign:0,
            p:0,
            businessType:1
        };
    },
    created() {
        this.sign = this.$route.query.sign ? this.$route.query.sign : 0;
        this.p = this.$route.query.p ? this.$route.query.p : 0;
        this.init();
    },
    computed: {
        pictureList() {
            return this.detailInfo.picture.filter(item => item.task_picture_types_id == 1);
        },
        picList() {
            return this.detailInfo.picture.filter(item => item.task_picture_types_id == 3);
        },
        fileList() {
            return this.detailInfo.picture.filter(item => item.task_picture_types_id == 4);
        }
    },
    mounted() {},
    methods: {
        async init() {
            const { id } = this.$route.params;
            this.loading = true;
            await Api.getNewTaskDetail(id).then(res => {
                this.detailInfo = res;
                this.businessType = this.detailInfo.category.business_type;
                this.typeParams.business_type = this.detailInfo.category.business_type;
                this.xData = pushObjValue(this.detailInfo.like_pictures_score_v2).slice(1, 8);
                this.getAdvancedServiceOptions(this.detailInfo.ticket.add_skills);
                this.loading = false;
                this.idReview = true;
            }).catch(err => {
                this.loading = false;
            });
        },
        async getAdvancedServiceOptions(skills) {
            const res = await Api.fetchAdvancedServiceOptions(this.typeParams);
            const names = skills.map(item => {
                const findTemp = res.find(innerItem =>
                    Number(innerItem.id) === Number(item));
                return (findTemp || {}).name;
            });
            this.skillList = names;
        },
        signUp() {
            const id = this.$route.params.id;
            if (this.$route.query.f == 1) {
                window.location.href = `https://edit.renwudingdong.com/freelancer/task_sign_ups?task_id=${id}`;
            } else {
                window.location.href = "https://edit.renwudingdong.com/freelancer/task_quiz_sign_ups?info=1&redirect_url=sign_up";
            }
        },
    }
};
</script>
<style>
.el-button--primary {
    background-color: #fa7d19 !important;
    border-color: #fa7d19 !important;
}
</style>
<style lang="sass" scoped>
    @import '../../common/style/detailPc.scss';
</style>