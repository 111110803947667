<template>
  <div class="head-container">
    <div class="head-content" :style="isMobile == true ? 'width:315px' : ''">
      <img src="../imgs/logo-change.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
name: 'headsale',
data() {
  return {
    isMobile: (/Android|webOS|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent)),
  }
}
}
</script>
<style lang="sass" scoped>
.head-container
  width: 100%
  height: 70px
  line-height: 70px
  background: #fff
  position: fixed
  top: 0
  left: 0
  z-index: 2000
  background-position: center;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,.12);
  .head-content
    width: 1200px
    margin: 0 auto
    img
      width: 120px
      height: 30px
</style>