import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import VueScrollactive from 'vue-scrollactive';
import VueCookies from 'vue-cookies';
import RwddEchart  from 'rwdd-echart';
import 'element-ui/lib/theme-chalk/index.css';

const echarts = require("echarts");
import App from './App.vue';
import Meta from 'vue-meta';
import { router } from './router/index.js';
Vue.prototype.$echarts = echarts;


Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(RwddEchart);
Vue.use(ElementUI);
Vue.use(VueScrollactive);
Vue.use(VueCookies);

new Vue({ el: '#app', router, render: h => h(App) });

