<template>
  <div class="designer-review">
    <div class="title-container">
      <template v-if="p== 1">
        <div class="main-title">偏好案例得分</div>
      </template>
      <template v-else>
        <div class="main-title">{{designerTitle}}</div>
      </template>
      <el-button v-if="isTaskLibrary" class="rule" type="text">了解评分细则</el-button>
    </div>
    <rwdd-echart :xData="xData" class="echarts-content" :business_type="businessType" :averageScore="averageScore" :radiusNum="radiusNum" :showType="line" :axisSize="axisSize"></rwdd-echart>
    <div class="main-title" v-if="tasksSkillsData.length > 0">{{tasksTitle}}</div>
    <div class="tasks-skills" :class="isTaskLibrary ? 'tasks-library' : 'new-tasks'" v-if="tasksSkillsData.length > 0">
      <div v-if="isTaskLibrary">高阶技能：</div>
      <div class="item" :class="!isTaskLibrary ? 'new-tasks-item' : ''" v-for="(item, index) in tasksSkillsData" :key="index">
        <img v-if="isTaskLibrary" src="../imgs/item.svg" alt="">
        <img v-else src="../imgs/right_icon.svg" alt="">
        <div class="text">{{item}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  nsme: 'designer-review',
  props: {
    // 是否是任务库详情
    isTaskLibrary: {
      type: Boolean,
      default: true
    },
    tasksSkillsData: {
      type: Array,
      default: () => ['定制化拍摄', '非常规字体', '定制化设计的字体', '很多种配色的搭配', '特别少东西的排版']
    },
	  averageScore: {
      type: Number,
      default: 0
	  },
    xData: {
      type: Array,
      default:[]
    },
    businessType: {
      type: Number,
      default: 1
    },
    p:{
      type: String,
    }
	
  },
  computed: {
    designerTitle () {
      return this.isTaskLibrary ? '设计师评审结果' : `所需${this.businessType == 2 ? '文案' : '设计'}水平`
    },
    tasksTitle () {
      return this.isTaskLibrary ? '任务涉及的高阶技能' : `任务涉及的${this.businessType == 2 ? '写作' : '设计'}难点`
    },
  },
  data () {
    return {
		  radiusNum: 40,
		  line: "line",
      axisSize:12
    }
  },
}
</script>
<style>
.echarts-content {
    position: relative;
    width: 260px !important;
    height: 180px !important;
}
.echarts-content   .innerBox {
    width: 260px !important;
    height: 180px !important;
}
.echarts-content .title {
    position: absolute;
    top: 50%;
    z-index: 8;
    font-size: 18px;
    font-weight: 600;
    color: #F87D32;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
<style lang="sass" scoped>
@import '@/common/style/design.scss';
</style>